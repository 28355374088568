import React, { FC, useEffect, useState } from 'react'
import { Head } from '../../layouts/head'
import { Header } from '../../layouts/header'
import { SideNavi } from '../../layouts/sideNavi'
import apiErrorHandler from '../../api/apiErrorHandler'
import {
  ApiError,
  MemberControllerService,
  MemberLogoutControllerService,
  MemberQuery,
  MemberWorkspaceControllerService,
  MemberWorkspaceQuery,
  WorkspaceSwitchingForm,
} from '../../services'
import { CubeExtractModal } from '../../modals/cubeExtractModal'
import { useModal } from '../../contexts/modalContext'
import { CareerEditModal } from '../../modals/careerEditModal'
import { ProfileEditModal } from '../../modals/profileEditModal'
import { ContactInfoEditModal } from '../../modals/contactInfoEditModal'
import { BusinessContentEditModal } from '../../modals/businessContentEditModal'
import { getCareerInfo } from '../../lib/common'
import { SafeParagraph } from '../../components/safeParagraph'
import { CubeSendModal } from '../../modals/cubeSendModal'
import { BasicInfo } from '../../components/BasicInfo'
import { CareerInfo } from '../../components/CareerInfo'
import { CubeList } from '../../components/cube/cubeList'
import useScrollToTop from '../../hooks/useScrollToTop'
import { SESSION_KEY } from '../../constants/session'

export const Profile: FC = () => {
  const [member, setMember] = useState({} as MemberQuery)
  const [careerInfo, setCareerInfo] = useState<string[]>([])
  const { openModal } = useModal()

  const [workspaces, setWorkspaces] = useState<MemberWorkspaceQuery[]>([])
  const [workspaceId, setWorkspaceId] = useState<string>('')
  useScrollToTop()
  useEffect(() => {
    MemberControllerService.getMember(0)
      .then(res => {
        setMember(res)
        setCareerInfo(getCareerInfo(res.careers))
      })
      .catch(apiErrorHandler)

    MemberWorkspaceControllerService.getWorkspaces()
      .then(res => {
        setWorkspaces(res)
        if (res.length > 1) {
          MemberControllerService.getLoggedInMember()
            .then(loggedInMember => {
              setWorkspaceId(loggedInMember.workspaceId)
            })
            .catch(apiErrorHandler)
        }
      })
      .catch(apiErrorHandler)
  }, [openModal])

  const switchWorkspace = (event: React.ChangeEvent<HTMLSelectElement>) => {
    MemberWorkspaceControllerService.switchWorkspace({
      workspaceId: event.target.value,
    } as WorkspaceSwitchingForm)
      .then(res => {
        // セッションを削除
        sessionStorage.removeItem(SESSION_KEY.TIMELINE.STORAGE_KEY)
        sessionStorage.removeItem(SESSION_KEY.TIMELINE.DETAIL_PATH_KEY)
        if (res.isFirstLogin) {
          window.location.href = '/sign-up'
        } else {
          window.location.href = '/'
        }
      })
      .catch(apiErrorHandler)
  }

  const logout = async () => {
    try {
      await MemberLogoutControllerService.logout()
      window.location.href = '/login'
    } catch (err) {
      await apiErrorHandler(err as ApiError)
    }
  }

  return (
    <>
      <Head />
      <Header />
      <SideNavi />
      <main className="ml-auto w-full md:w-[calc(100%-223px)] ">
        <div className="relative h-[155px] mt-12 p-4 bg-profile-sp bg-cover bg-center md:h-[261px] md:bg-profile-lg md:mt-0 md:py-20 md:px-6">
          <div className="hidden justify-start items-center gap-5 max-w-1617 mx-auto md:flex">
            <div className="flex gap-4 items-center">
              <img
                src={
                  member.profileImageUrl
                    ? member.profileImageUrl
                    : '/icons/avatar-sample.png'
                }
                alt="プロフィール画像"
                className="w-24 rounded-full"
                onClick={() => openModal(ProfileEditModal, { member: member })}
              />
              <div className="text-white">
                <h2
                  className="relative pr-6 text-xl font-bold line-clamp-2"
                  tabIndex={0}
                  onClick={() =>
                    openModal(ProfileEditModal, { member: member })
                  }
                >
                  {member.memberName}
                  <svg
                    className="icon-center right-0 cursor-pointer"
                    width="14"
                    height="14"
                    viewBox="0 0 14 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M11.2583 5.2056L8.77917 2.7556L9.59584 1.93893C9.81945 1.71532 10.0942 1.60352 10.4201 1.60352C10.746 1.60352 11.0205 1.71532 11.2438 1.93893L12.0604 2.7556C12.284 2.97921 12.4007 3.2491 12.4104 3.56527C12.4201 3.88143 12.3132 4.15113 12.0896 4.37435L11.2583 5.2056ZM2.33334 12.2493C2.16806 12.2493 2.02942 12.1933 1.91742 12.0813C1.80542 11.9693 1.74961 11.8309 1.75 11.666V10.0181C1.75 9.94032 1.76459 9.86488 1.79375 9.79177C1.82292 9.71866 1.86667 9.65313 1.925 9.59518L7.93334 3.58685L10.4125 6.06602L4.40417 12.0744C4.34584 12.1327 4.28031 12.1764 4.20759 12.2056C4.13486 12.2348 4.05942 12.2493 3.98125 12.2493H2.33334Z"
                      fill="#FFFFFF"
                    />
                  </svg>
                </h2>

                <p className="line-clamp-2">{member?.memberNameFurigana}</p>

                <div className="line-clamp-2">
                  {member.position?.positionName}
                </div>
                {careerInfo.length > 0 && (
                  <div className="line-clamp-2">
                    {careerInfo.map((info, index) => (
                      <React.Fragment key={index}>
                        {index > 0 && <br />}
                        {info}
                      </React.Fragment>
                    ))}
                  </div>
                )}
              </div>
            </div>

            {member.singleWord && (
              <div className="relative flex max-w-[40%] min-h-10 mt-7 mb-12 p-3 rounded-2xl bg-white font-bold text-xs">
                <SafeParagraph
                  className="relative line-clamp-4"
                  content={member.singleWord}
                />
                <svg
                  className="absolute bottom-[-9px] left-[16%]"
                  width="13"
                  height="10"
                  viewBox="0 0 13 10"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M0.5 0H12.5C9.5 8 0.5 9.5 0.5 9.5C0.5 9.5 2.5 4 0.5 0Z"
                    fill="#F2F4F7"
                  />
                </svg>
              </div>
            )}
          </div>

          {member.singleWord && (
            <div className="block relative max-w-640 min-h-16 mx-auto mt-[-5px] p-3 rounded-xl bg-neutral-paleGray md:hidden">
              <SafeParagraph
                className="text-xs line-clamp-4"
                content={member.singleWord}
              />
              <svg
                className="absolute right-1/2 bottom-[-9px]"
                width="13"
                height="10"
                viewBox="0 0 13 10"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M0.5 0H12.5C9.5 8 0.5 9.5 0.5 9.5C0.5 9.5 2.5 4 0.5 0Z"
                  fill="#F2F4F7"
                />
              </svg>
            </div>
          )}

          {/* TODO ディグラム診断 */}
          {/*
          <div className="profile_page__head-btn-wrapper">
            <button type="button" className="btn_profile_page-caution">
              ディグラム診断がまだ完了していません
            </button>
          </div>
          */}

          <div className="md:hidden block absolute w-24 h-24">
            <img
              src={
                member.profileImageUrl
                  ? member.profileImageUrl
                  : '/icons/avatar-sample.png'
              }
              alt="プロフィール画像"
              className="w-24 rounded-full"
              onClick={() => openModal(ProfileEditModal, { member: member })}
            />
          </div>
        </div>

        <div className="md:hidden block p-4 bg-neutral-paleGray">
          <p className="ml-auto text-xs w-fit relative">
            <a href="/help" className="text-green relative">
              parksの使い方
              <span className="icon-center right-20">
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 21"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M5.83301 2.16602C4.4523 2.16602 3.33301 3.28531 3.33301 4.66602V16.3327C3.33301 17.7134 4.4523 18.8327 5.83301 18.8327H15.833C16.2933 18.8327 16.6663 18.4596 16.6663 17.9993C16.6663 17.8593 16.6318 17.7274 16.5708 17.6117L16.1177 16.7053C16.0004 16.4708 16.0004 16.1946 16.1177 15.96L16.5723 15.0508C16.6349 14.9256 16.6663 14.7973 16.6663 14.6577V3.83268C16.6663 2.91221 15.9202 2.16602 14.9997 2.16602H5.83301ZM14.506 15.4993H5.83301C5.37277 15.4993 4.99967 15.8724 4.99967 16.3327C4.99967 16.7929 5.37277 17.166 5.83301 17.166H14.506C14.3154 16.627 14.3154 16.0383 14.506 15.4993ZM7.49967 7.16602C7.49967 6.70578 7.87277 6.33268 8.33301 6.33268H11.6663C12.1266 6.33268 12.4997 6.70578 12.4997 7.16602C12.4997 7.62625 12.1266 7.99935 11.6663 7.99935H8.33301C7.87277 7.99935 7.49967 7.62625 7.49967 7.16602ZM8.33309 9.875C7.87286 9.875 7.49976 10.2481 7.49976 10.7083C7.49976 11.1686 7.87286 11.5417 8.33309 11.5417H11.6664C12.1267 11.5417 12.4998 11.1686 12.4998 10.7083C12.4998 10.2481 12.1267 9.875 11.6664 9.875H8.33309Z"
                    fill="#007559"
                  />
                </svg>
              </span>
            </a>
          </p>
          <h2
            className="mt-2.5 relative font-bold text-xl pr-5 w-fit line-clamp-3"
            tabIndex={0}
            onClick={() => openModal(ProfileEditModal, { member: member })}
          >
            {member.memberName}
            <svg
              width="14"
              height="14"
              viewBox="0 0 14 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="icon-center right-0 cursor-pointer"
            >
              <path
                d="M11.2583 5.2056L8.77917 2.7556L9.59584 1.93893C9.81945 1.71532 10.0942 1.60352 10.4201 1.60352C10.746 1.60352 11.0205 1.71532 11.2438 1.93893L12.0604 2.7556C12.284 2.97921 12.4007 3.2491 12.4104 3.56527C12.4201 3.88143 12.3132 4.15113 12.0896 4.37435L11.2583 5.2056ZM2.33334 12.2493C2.16806 12.2493 2.02942 12.1933 1.91742 12.0813C1.80542 11.9693 1.74961 11.8309 1.75 11.666V10.0181C1.75 9.94032 1.76459 9.86488 1.79375 9.79177C1.82292 9.71866 1.86667 9.65313 1.925 9.59518L7.93334 3.58685L10.4125 6.06602L4.40417 12.0744C4.34584 12.1327 4.28031 12.1764 4.20759 12.2056C4.13486 12.2348 4.05942 12.2493 3.98125 12.2493H2.33334Z"
                fill="#8a8f9f"
              />
            </svg>
          </h2>

          <p className="mt-1 font-bold text-sm line-clamp-2">
            {member.memberNameFurigana}
          </p>
          <div className="mt-1 font-normal text-xs line-clamp-2">
            {member.position?.positionName}
          </div>
          {careerInfo.length > 0 && (
            <div className="mt-1 font-normal text-xs line-clamp-2">
              {careerInfo.map((info, index) => (
                <React.Fragment key={index}>
                  {index > 0 && <br />}
                  {info}
                </React.Fragment>
              ))}
            </div>
          )}
        </div>

        <div className="md:px-10 px-0 md:my-12 my-2">
          {workspaces.length > 1 && (
            <div className="profile__container">
              <div className="profile__card p-[16px_15px]">
                <div className="block md:flex justify-between items-center">
                  <h3 className="pr-6 font-bold text-lg md:inline-block flex-shrink-0 hidden">
                    ワークスペース切り替え
                  </h3>
                  <div className="relative w-full md:max-w-80">
                    <select
                      className="p-2 pl-4 rounded-lg bg-gray-100 cursor-pointer w-full"
                      value={workspaceId}
                      onChange={switchWorkspace}
                    >
                      {workspaces.map((option, index) => (
                        <option
                          key={index}
                          value={option.workspaceId}
                          disabled={!option.workspaceName}
                        >
                          {option.workspaceName}
                        </option>
                      ))}
                    </select>
                    <svg
                      className="icon-center right-2 pointer-events-none"
                      width="20"
                      height="21"
                      viewBox="0 0 20 21"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M13.0983 8.5L6.90169 8.5C6.15069 8.5 5.73001 9.25351 6.19399 9.7676L9.29231 13.2006C9.65265 13.5998 10.3474 13.5998 10.7077 13.2006L13.806 9.7676C14.27 9.25351 13.8493 8.5 13.0983 8.5Z"
                        fill="#8A8F9F"
                      />
                    </svg>
                  </div>
                </div>
              </div>
            </div>
          )}
          <div className="profile__container">
            <div className="p-4 mt-3 bg-white md:rounded-xl md:p-6 md:mt-6 md:shadow-lg">
              <div className="flex items-center">
                <h3
                  className="pr-6 font-bold text-lg relative cursor-pointer"
                  tabIndex={0}
                  onClick={() =>
                    openModal(BusinessContentEditModal, { member: member })
                  }
                >
                  主な業務内容
                  <svg
                    width="14"
                    height="14"
                    viewBox="0 0 14 14"
                    fill="none"
                    className="icon-center right-0"
                    xmlns="http://www.w3.org/2000/svg"
                    aria-hidden="true"
                  >
                    <path
                      d="M11.2583 5.2056L8.77917 2.7556L9.59584 1.93893C9.81945 1.71532 10.0942 1.60352 10.4201 1.60352C10.746 1.60352 11.0205 1.71532 11.2438 1.93893L12.0604 2.7556C12.284 2.97921 12.4007 3.2491 12.4104 3.56527C12.4201 3.88143 12.3132 4.15113 12.0896 4.37435L11.2583 5.2056ZM2.33334 12.2493C2.16806 12.2493 2.02942 12.1933 1.91742 12.0813C1.80542 11.9693 1.74961 11.8309 1.75 11.666V10.0181C1.75 9.94032 1.76459 9.86488 1.79375 9.79177C1.82292 9.71866 1.86667 9.65313 1.925 9.59518L7.93334 3.58685L10.4125 6.06602L4.40417 12.0744C4.34584 12.1327 4.28031 12.1764 4.20759 12.2056C4.13486 12.2348 4.05942 12.2493 3.98125 12.2493H2.33334Z"
                      fill="#8a8f9f"
                    />
                  </svg>
                </h3>
              </div>
              <SafeParagraph
                className="mt-4 text-sm md:mt-2"
                content={member.businessContent}
              />
            </div>
          </div>
          <div className="profile__container">
            <div className="profile__card mt-3 md:mt-6">
              <CubeList
                member={member}
                targetMember={member}
                titleClassName="pr-6 font-bold text-lg md:pr-6"
                listClassName="flex flex-wrap mt-3 gap-x-2 gap-y-1"
                onAddClick={() => {
                  openModal(CubeSendModal, { memberId: member.memberId })
                }}
                onExtractClick={() => {
                  openModal(CubeExtractModal, { memberId: member.memberId })
                }}
                isProfile={true}
              />
            </div>
          </div>
          <div className="profile__container flex flex-col md:flex-row justify-between items-start mt-3 md:mt-6 gap-3">
            <div className="profile__card md:w-7/12 w-full">
              <CareerInfo
                careers={member.careers}
                titleClassName="font-bold text-lg"
                onAddClick={() =>
                  openModal(CareerEditModal, { careers: member.careers })
                }
                onEditClick={career =>
                  openModal(CareerEditModal, {
                    careers: member.careers,
                    target: career,
                  })
                }
                isProfilePage={true}
              />
            </div>

            <div className="profile__card max-w-640 mx-auto w-full min-w-52 p-4 md:w-2/5">
              <BasicInfo
                member={member}
                titleClassName="inline-block font-bold text-lg"
                onClick={() => openModal(ContactInfoEditModal, { member })}
                isProfilePage={true}
              />
            </div>
          </div>
          <div className="profile__container">
            <div className="profile__card mt-6 md:flex md:justify-between md:items-center">
              <h3 className="inline-block pr-6 font-bold text-lg md:pr-6">
                メール設定
              </h3>
              <div className="mt-4 md:mt-0 md:flex md:items-center">
                <button
                  className="btn-base btn-outline-base w-full px-5 py-3"
                  onClick={() => {
                    window.location.href = '/setting'
                  }}
                >
                  メール設定を変更
                </button>
              </div>
            </div>
          </div>
          <button
            className="btn-base block mt-10 mx-auto text-green mb-20 md:mb-0"
            onClick={logout}
          >
            ログアウト
          </button>
        </div>
      </main>
    </>
  )
}
