import { FC, useState, useEffect } from 'react'
import { Button } from '../../components/button'
import { QuestionControllerService } from '../../services'
import apiErrorHandler from '../../api/apiErrorHandler'
import { toast } from 'react-toastify'
interface Props {
  closeModal: () => void
  setIsLoading: (isLoading: boolean) => void
}

export const QuestionForm: FC<Props> = ({ closeModal, setIsLoading }) => {
  // 質問内容入力
  const [questionValue, setQuestionValue] = useState('')
  const handleQuestionChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>,
  ) => {
    setQuestionValue(event.target.value)
  }

  // 回答の選択肢入力
  const [answerValues, setAnswerValues] = useState<string[]>(['', ''])
  const handleAnswerChange = (
    index: number,
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const newValues = [...answerValues]
    newValues[index] = event.target.value // 該当インデックスの値を更新
    setAnswerValues(newValues)
  }

  // 回答の選択肢追加
  const addAnswer = () => {
    if (answerValues.length < 4) {
      setAnswerValues([...answerValues, ''])
    }
  }

  // 匿名で質問するチェックボックス
  const [isAnonymous, setIsAnonymous] = useState(false)
  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsAnonymous(event.target.checked)
  }

  // 入力欄が入力済みか
  const [isEmptyForm, setIsEmptyForm] = useState(false)

  useEffect(() => {
    // 質問内容と選択肢(2件以上)が入力済みかを確認
    const hasTwoOrMoreAnswers =
      answerValues.filter(value => value.trim() !== '').length >= 2
    const isEmpty = questionValue.trim() === '' || !hasTwoOrMoreAnswers
    setIsEmptyForm(isEmpty)
  }, [questionValue, answerValues])

  const createQuestion = () => {
    const questionForm = {
      question: questionValue,
      choices: answerValues.filter(answer => answer !== ''),
      anonymousFlg: isAnonymous,
    }
    setIsLoading(true)

    QuestionControllerService.createQuestion(questionForm)
      .then(() => {
        toast.success('登録しました')
        closeModal()
      })
      .catch(apiErrorHandler)
      .finally(() => {
        setIsLoading(false)
      })
  }
  return (
    <>
      <div className="flex flex-col items-start gap-5 mt-5 mb-24 max-h-full md:max-h-[500px] overflow-y-auto">
        <p className="w-full">
          <label className="font-bold text-sm block text-left">
            質問内容
            <textarea
              className="block w-full mt-1 p-3.5 rounded-lg bg-gray-100 font-normal text-base h-24 resize-none"
              name="description"
              placeholder="例）犬と猫どちらが好きですか？"
              value={questionValue}
              onChange={handleQuestionChange}
              maxLength={140}
            ></textarea>
          </label>
        </p>
        {answerValues.map((value, index) => (
          <div key={index} className="w-full">
            <label className="font-bold text-sm block text-left">
              選択肢{index + 1}
              <input
                type="text"
                value={value}
                onChange={event => handleAnswerChange(index, event)}
                placeholder="選択肢を入力してください"
                maxLength={25}
                className="block w-full mt-1 p-3.5 rounded-lg bg-gray-100 font-normal text-base"
              />
            </label>
          </div>
        ))}
        {answerValues.length < 4 && (
          <Button
            className="min-w-32 px-6 py-3 border border-green bg-white text-green rounded-full font-bold text-sm"
            imageUrl="/images/icon_add.svg"
            onClick={addAnswer}
          >
            選択肢を追加
          </Button>
        )}
        <p className="flex items-center space-x-2">
          <input
            type="checkbox"
            id="anonymous-post"
            name="anonymous-post"
            checked={isAnonymous}
            onChange={handleCheckboxChange}
            className="h-4 w-4 border border-gray-300 rounded-sm checked:bg-green checked:border-green checked:bg-[url('/images/icon_checked.svg')] checked:bg-center checked:bg-no-repeat focus:ring-green"
          />
          <label htmlFor="anonymous-post" className="cursor-pointer text-sm">
            匿名で質問する
          </label>
        </p>
      </div>
      <div className="fixed inset-x-0 bottom-0 flex justify-center mb-5 gap-2">
        <Button
          className="w-32 px-6 py-3 rounded-full font-bold text-sm flex justify-center items-center btn-outline-base"
          onClick={closeModal}
        >
          キャンセル
        </Button>
        <Button
          className="w-32 px-6 py-3 flex justify-center items-center btn-primary-base"
          disabled={isEmptyForm}
          onClick={createQuestion}
        >
          投稿する
        </Button>
      </div>
    </>
  )
}
