import React from 'react'
import { formatDate } from '../../lib/common'
import { MemberQuery } from '../../services'

interface BasicInfoProps {
  member: MemberQuery
  titleClassName: string
  onClick?: () => void
  dateOfBirthShowFlg?: boolean
  isProfilePage?: boolean
}

export const BasicInfo: React.FC<BasicInfoProps> = ({
  member,
  titleClassName,
  onClick,
  dateOfBirthShowFlg,
  isProfilePage = false,
}) => {
  return (
    <div>
      <div className="flex items-center">
        <h3 className={titleClassName} tabIndex={0}>
          基本情報
        </h3>
        {isProfilePage && (
          <div className="relative flex items-center">
            <svg
              className="ml-2 cursor-pointer text-gray-600"
              width="14"
              height="14"
              viewBox="0 0 14 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              onClick={onClick}
            >
              <path
                d="M11.2583 5.2056L8.77917 2.7556L9.59584 1.93893C9.81945 1.71532 10.0942 1.60352 10.4201 1.60352C10.746 1.60352 11.0205 1.71532 11.2438 1.93893L12.0604 2.7556C12.284 2.97921 12.4007 3.2491 12.4104 3.56527C12.4201 3.88143 12.3132 4.15113 12.0896 4.37435L11.2583 5.2056ZM2.33334 12.2493C2.16806 12.2493 2.02942 12.1933 1.91742 12.0813C1.80542 11.9693 1.74961 11.8309 1.75 11.666V10.0181C1.75 9.94032 1.76459 9.86488 1.79375 9.79177C1.82292 9.71866 1.86667 9.65313 1.925 9.59518L7.93334 3.58685L10.4125 6.06602L4.40417 12.0744C4.34584 12.1327 4.28031 12.1764 4.20759 12.2056C4.13486 12.2348 4.05942 12.2493 3.98125 12.2493H2.33334Z"
                fill="#8a8f9f"
              />
            </svg>
          </div>
        )}
      </div>
      <dl className="flex flex-col mt-4 gap-4">
        <div className="flex flex-col gap-1">
          <dt className="text-gray-500 font-bold text-sm">生年月日</dt>
          <dd className="font-normal text-sm">
            {dateOfBirthShowFlg === false || !member.dateOfBirth
              ? '-'
              : formatDate(member.dateOfBirth, 'yyyy年MM月dd日')}
          </dd>
        </div>
        <div className="flex flex-col gap-1">
          <dt className="text-gray-500 font-bold text-sm">入社日</dt>
          <dd className="font-normal text-sm">
            {member.dateOfEntry
              ? formatDate(member.dateOfEntry, 'yyyy年MM月dd日')
              : '-'}
          </dd>
        </div>
        <div className="flex flex-col gap-1">
          <dt className="text-gray-500 font-bold text-sm">メールアドレス</dt>
          <dd className="font-normal text-sm">{member.email}</dd>
        </div>
      </dl>
    </div>
  )
}
